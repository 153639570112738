  .splash_IT{
    cursor : pointer;
    position : fixed;
    top : 50%;
    left : 49%;
    height : 120%;
    width : 110%;
    transform : translate(-50%,-55%);    
    background-color:white; 
    transition : all ease-in-out 600ms;
    z-index: 8;
  }
  .hidden_IT{
    transition : 0.5s;
    display : none;
  }
  .splash-header_IT {
    height : 100%;
    background-color : white;
    font-size : 30px;
    display : flex;
    justify-content: center;
    align-items : center;
  }

  .logo_SplashScreen_IT{
    height: 45vh;
    margin-left: 5%;
    margin-top: 5%;
  }

  @media only screen and (min-width: 1200px) {
    .logo_SplashScreen_IT{
        height: 97vh;
        width: 70vw;
      }
  }

.splash_WH{
    cursor : pointer;
    position : fixed;
    top : 50%;
    left : 49%;
    height : 120%;
    width : 110%;
    transform : translate(-50%,-55%);    
    background-color:white; 
    transition : all ease-in-out 600ms;
    z-index: 8;
  }
  .hidden_WH{
    transition : 0.5s;
    display : none;
  }
  .splash-header_WH {
    height : 95%;
    color : white;
    font-size : 30px;
    display : flex;
    justify-content: center;
    align-items : center;
  }

  .logo_SplashScreen_WH{
    height: 30vh;
    margin-left: 18%;
    margin-top: 5%;
    /* background-color: green; */
  }

  @media only screen and (min-width: 1200px) {
    .logo_SplashScreen_WH{
        height: 22vw;
        width: 35vw;
        margin-left: 5%;
      }
  }
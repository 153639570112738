.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 4px;
  background-color: teal;
  margin: 6px 0;
  border-radius: 5vw;
  transition: 0.4s;
  z-index: 10;
}

.change .bar1 {
  transform: translate(0, 10px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -10px) rotate(45deg);
}

.menu_bg {
  background: black;
  color: white;
  transition: 0.8s ease-in-out;
  overflow-y: auto;
}

.bookCall_btn {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  padding-block: 0.2rem;
  padding-inline: 0.5rem;
  background-color: #C2F8ED;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 8px;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
}

.icon {
  margin-top: 6px;
  width: 22px;
  height: 22px;
  transition: all 0.3s ease-in-out;
}

.bookCall_btn:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.bookCall_btn:hover .icon {
  transform: translate(4px);
}

.bookCall_btn:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.bookCall_btn::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.bg_blur{
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px); 
}


.toggle_lightDarkMode {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  line-height: 1;
}

.input_lightDarkMode {
  display: none;
}

.icon_lightDarkMode {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  transition: transform 500ms;
}

.icon--moon_lightDarkMode {
  transition-delay: 200ms;
}

.icon--sun_lightDarkMode {
  transform: scale(0);
}

#switch:checked + .icon--moon_lightDarkMode {
  transform: rotate(360deg) scale(0);
}

#switch:checked ~ .icon--sun_lightDarkMode {
  transition-delay: 200ms;
  transform: scale(1) rotate(360deg);
}

/* Footer css starts from here */
.btn {
  padding: 2% 6%;
  background: teal;
  border: solid 2px teal;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  transition: color 500ms;
}

.gradient_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0));
}

.label_btn {
  position: relative;
  z-index: 1;
}

.transition_btn {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: white;
  border-radius: 9999px;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn:hover .transition_btn {
  width: 14em;
  height: 14em;
}

.btn:hover,
.btn:active {
  color: teal;
}

.btn:hover .label_btn,
.btn:active .label_btn {
  color: teal;
}
/* Footer css ends here */
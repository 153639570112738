html {
  scroll-behavior: smooth;
}

.honeycomb_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

.row {
  display: flex;
  gap: 15px;
  margin-top: -40px;
}

.offset {
  margin-left: 5px;
  margin-top: -40px;
}

.hexagon {
  position: relative;
  width: 200px;
  height: 220px;
  background-color: #c2f8ed;
  clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  ) !important;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.hexagon .content {
  color: #008080;
}

.hexagon:hover {
  transform: scale(1.1);
}

.hexagon .overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 128, 0.9);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  transition: bottom 0.4s ease;
}

.hexagon:hover .overlay {
  bottom: 0;
}

input[type="checkbox"] {
  height: 15px;
  border: none;
  outline: none;
  border-radius: 2vw;
}

.checkbox_Home {
  width: 15px;
  background-color: white;
  border-radius: 100%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  padding: 1.8vw;
}

@media screen and (min-width: 1024px) {
  .checkbox_Home {
    padding: 1vw;
  }
}

.checkbox_Home:checked {
  background-color: greenyellow;
}

.quotaSync_Home {
  font-weight: 500;
  display: inline;
  border: none;
  outline: none;
  background-color: transparent;
}

.waveriseHub_Home {
  font-weight: 500;
  display: inline;
  margin-left: 5%;
  border: none;
  outline: none;
  background-color: transparent;
}

.active_quotaSync_Home,
.active_waveriseHub_Home {
  text-decoration: underline;
  text-decoration-color: teal;
  text-underline-offset: 0.5rem;
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}

.quotaSync_Home:hover,
.waveriseHub_Home:hover {
  text-decoration: underline;
  text-decoration-color: teal;
  text-underline-offset: 0.5rem;
  transition: opacity 0.5s ease-in-out;
}

@keyframes progress {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

.animate-progress {
  animation: progress 24s linear infinite;
}

.react-multiple-carousel__arrow {
  position: absolute !important;
  outline: 0 !important;
  transition: all 0.5s !important;
  border-radius: 200px !important;
  z-index: 1 !important;
  border: 0 !important;
  background: rgba(0, 0, 0, 0.2) !important;
  min-width: 28px !important;
  min-height: 10px !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
}

.discoverMore_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: inherit;
  margin: auto;
  margin-top: 8%;
  letter-spacing: 0.4px;
  color: white;
  background-color: #008080;
  border-style: solid;
  font-weight: 200;
  border-radius: 6px;
  padding: 2% 7% 2% 7%;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
}

.discoverMore_btn:hover {
  color: #008080;
  background-color: #f5fffe;
  box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%);
}

.discoverMore_btn:active {
  box-shadow: none;
}

.transition_opacity {
  transition: opacity 0.5s ease-in-out;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 20px 0;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  width: 95%;
  position: relative;
}

.milestone {
  position: relative;
  flex: 1;
  text-align: center;
}

.milestone-icon {
  font-size: 20px;
  position: relative;
  display: inline-block;
  padding: 7px 6px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
  z-index: 2;
  transition: border-color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.milestone::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 50%;
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  z-index: 0;
  transform: translateX(-50%);
}

.milestone:first-child::before {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.milestone:last-child::before {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.milestone::after {
  content: "";
  position: absolute;
  top: 18%;
  left: 50%;
  width: 0%;
  height: 16px;
  background-color: #008080;
  z-index: 1;
  transform: translateX(-50%);
  transition: width 0.4s ease-in-out;
}

@media (max-width: 1024px) {
  .milestone-icon {
    font-size: 14px;
  }

  .milestone::before {
    top: 15%;
  }

  .milestone::after {
    top: 15%;
  }
}

.milestone.completed::after {
  width: 100%;
}

.milestone:first-child::after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.milestone:last-child::after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.milestone-number {
  position: relative;
  display: inline-block;
  padding: 0px 9px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
  z-index: 2;
  transition: border-color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.milestone.completed .milestone-icon {
  border-color: #008080;
  background-color: #008080;
  color: white;
}

.milestone.completed .milestone-text {
  color: #008080;
}

/* Careers css starts from here */
.btn_careers {
  padding: 0.5% 2%;
  background: teal;
  border: solid 2px teal;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  transition: color 500ms;
}

.gradient_btn_careers {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.label_btn_careers {
  position: relative;
  z-index: 1;
}

.transition_btn_careers {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 500ms;
  background-color: white;
  border-radius: 9999px;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn_careers:hover .transition_btn_careers {
  width: 14em;
  height: 14em;
}

.btn_careers:hover,
.btn_careers:active {
  color: teal;
}

.btn_careers:hover .label_btn_careers,
.btn_careers:active .label_btn_careers {
  color: teal;
}

/* Careers css ends here */

/* Case Study css starts from here */
@font-face {
  font-family: Project_title;
  src: url("/public/assets/fonts/Megrim/Megrim-Regular.ttf");
}

.project_name {
  font-family: Project_title;
}

.popup_form_Careers {
  height: auto;
  width: 50%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .popup_form_Careers {
    width: 30%;
  }
}

.overlay_Careers {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.close_Careers {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #008080;
}

/* Case Study css ends here */
